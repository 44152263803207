import { PhoneForwarded } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";

const ContactSection = () => {
  return (
    <>
      <Box sx={{ width: "100%", padding: "4%", zIndex: "-1" }}>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={8}>
            <Box style={{ textAlign: "left", marginLeft: "7%" }}>
              <Typography
                variant="h4"
                style={{ fontFamily: "Segoe UI Italic" }}
              >
                Obtain futher information by making contact with our experienced
                staff.
              </Typography>
              <br />
              <Typography
                variant="h6"
                style={{ fontFamily: "Segoe UI Italic" }}
              >
                Tailored solutions that perfectly match the needs of our
                clients.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box style={{ textAlign: "center", marginTop: "2%" }}>
              <Typography
                variant="h4"
                style={{ fontFamily: "Segoe UI Italic" }}
              >
                <PhoneForwarded /> +92 308 4557448
              </Typography>
              <Box style={{ textAlign: "center" }}>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    bordercolor="#b28800"
                    style={{
                      marginTop: "3%",
                      fontFamily: "Segoe UI Italic",
                      color: "#b28800",
                      borderColor: "#b28800",
                    }}
                  >
                    Contact us
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactSection;
