import { Typography, Box, Grid, Button, TextField } from "@mui/material";
import React, { useState, useRef } from "react";
// import emailjs from "@emailjs/browser";

const initialValue = {
  name: "",
  email: "",
  message: "",
};
const Contact = () => {
  const [user, setUser] = useState(initialValue);
  const { name, email, message } = user;

  const onValueChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(user);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs
    //   .sendForm(
    //     "service_6j24a7n",
    //     "template_jpzw0bg",
    //     form.current,
    //     "mRB0fNd7KGPsKmnKr"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       console.log(e.target.reset());
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };
  return (
    <>
      <Box>
        <Box>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13599.652139182843!2d74.3046613!3d31.5540012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49100c54ced161f9!2sChauburji%2C%20Block%20B%20New%20Chauburji%20Park%2C%20Lahore%2C%20Punjab%2054000!5e0!3m2!1sen!2s!4v1677320493952!5m2!1sen!2s"
            width="100%"
            height="450"
            title="map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
        <hr
          style={{
            marginBottom: "-1px",
            marginTop: "-5px",
            background: "#b28800",
            height: "7px",
            borderRadius: "4px",
            boxShadow: "1px 1px 2px #b28800, 0 0 25px #b28800, 0 0 5px #b28800",
          }}
        />
        <Box
          style={{
            padding: "1%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6}>
                <Box style={{ maxWidth: "90%", marginTop: "2%" }}>
                  <Typography variant="h4">
                    To Make Request For Further Information Contact Us
                  </Typography>
                  <Typography sx={{ lineHeight: 1.5, marginTop: "5%" }}>
                    <strong>Mailing Address:</strong>
                    <br />
                    Office # 06-LG, Alvi Grand Mall, Zaraar Shaheed road Cantt, Lahore, Pakistan.
                  </Typography>
                  <Typography sx={{ lineHeight: 1.5 }}>
                    <strong>Registered Address:</strong>
                    <br />
                    House # 14-S-2, street # 8, Bagh Gul Begum Fateh Shar Road
                    Mozang Lahore, Pakistan.
                  </Typography>
                  <Typography sx={{ lineHeight: 1.5 }}>
                    <strong>Phone#:</strong> <br />
                    +92 308 4557448
                  </Typography>
                  <Typography sx={{ lineHeight: 1.5 }}>
                    <strong>Email:</strong>
                    <br />
                    info@neweralogistics.pk
                  </Typography>
                  <Typography></Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box style={{ maxWidth: "90%" }}>
                  <Typography variant="h4">Send Your Query</Typography>
                  <form ref={form}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      name="user_name"
                      value={name}
                      style={{ width: "100%", marginTop: "2%" }}
                      color="warning"
                      onChange={(e) => onValueChange(e)}
                    />
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      name="user_email"
                      value={email}
                      style={{ width: "100%", marginTop: "2%" }}
                      color="warning"
                      onChange={(e) => onValueChange(e)}
                    />
                    <TextField
                      id="message"
                      label="Message"
                      variant="outlined"
                      name="message"
                      value={message}
                      style={{
                        width: "100%",
                        marginTop: "2%",
                      }}
                      color="warning"
                      multiline="true"
                      rows="5"
                      onChange={(e) => onValueChange(e)}
                    />
                    <Box style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="inherit"
                        onClick={(e) => sendEmail(e)}
                        style={{
                          marginTop: "3%",
                          fontFamily: "Segoe UI Italic",
                          color: "#ce7e00",
                          borderColor: "#ce7e00",
                          width: "50%",
                          minHeight: "50px",
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* <FooterSection /> */}
    </>
  );
};

export default Contact;
