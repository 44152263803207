import React from "react";
import AboutSection from "../../AboutSection/AboutSection";
import Carousel from "../../Carousel/Carousel";
import ContactSection from "../../ContactSection/ContactSection";
import FooterSection from "../../FooterSection/FooterSection";
import ServicesSection from "../../ServicesSection/ServicesSection";

const Home = () => {
  return (
    <>
      <Carousel />
      <AboutSection/>
      <ServicesSection/>
      <ContactSection/>
      <FooterSection/>
    </>
  );
};

export default Home;
