import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Pages/Home/Home";
import Services from "./components/Services/Services";
import Contact from "./components/Pages/Contact/Contact";
import AboutSection from "./components/AboutSection/AboutSection";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/about"  exact component={AboutSection} />
        <Route path="/services"  exact component={Services}  />
        <Route path="/contact" exact component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;
