import * as React from "react";
import { Typography, Box, Grid } from "@mui/material";
import air from "../../assets/ServicesPage/air.jpg";
import ship from "../../assets/ServicesPage/ship.jpeg";
import custom from "../../assets/ServicesPage/custom-terms.jpg";
import warehouse from "../../assets/ServicesPage/warehouse.jpg";

const Services = () => {
  return (
    <>
      {/*  Start Air Freight */}
      <Box
        style={{
          padding: "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "2%",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxWidth: "80%",
                  marginLeft: "10%",
                  marginTop: "2%",
                }}
              >
                <img
                  src={air}
                  className="d-block w-100"
                  style={{ borderRadius: "2%" }}
                  alt="..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxHeight: "100%",
                  maxWidth: "80%",
                  borderRadius: "2%",
                  marginLeft: "10%",
                  marginTop: "2%",
                }}
              >
                <Typography
                  variant="p"
                  style={{ fontSize: 20, fontFamily: "Segoe UI Italic" }}
                >
                  <strong>Air Freight</strong> is highly beneficial for import
                  and export business. It is advantageous if you send or receive
                  bulky items from one country to another with reasonable rates.
                  <br />
                  <strong>New Era Logistics</strong> can change in the blink of
                  an eye and we are committed to providing stable, reliable
                  services which maintain your supply chain’s integrity. Whether
                  your shipments fly in the bellies of commercial aircraft or
                  require specialist freighter aircraft to move them our team of
                  air freight experts will deliver tailor-made solutions to
                  solve your business challenges.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* End Air Freight */}
      {/* Start ship freight */}
      <Box
        style={{
          padding: "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "2%",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxHeight: "100%",
                  maxWidth: "80%",
                  borderRadius: "2%",
                  marginLeft: "10%",
                  marginTop: "1%",
                }}
              >
                <Typography
                  variant="p"
                  style={{ fontSize: 20, fontFamily: "Segoe UI Italic" }}
                >
                  <strong>Sea Freight</strong> is a method of transporting large
                  amounts of goods using carrier ships. Goods are packed into
                  containers and then loaded onto a vessel. A typical cargo ship
                  can carry around 18,000 containers, which means that sea
                  freight is a cost-efficient way to transport high quantities
                  over large distances. There are a number of ways in which sea
                  freight can be transported. <br />
                  <strong>FCL or Full container load</strong>, which you
                  purchase one or more full containers to send on a ship. <br />
                  <strong>LCL or Less than container load</strong>, where your
                  products share a container as you may not have a full
                  container’s worth. Once they reach their destination, the
                  container’s contents are divided once more.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxWidth: "80%",
                  marginLeft: "10%",
                }}
              >
                <img
                  src={ship}
                  className="d-block w-100"
                  style={{ borderRadius: "2%" }}
                  alt="..."
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* End ship freight */}
      {/* Start warehouse freight */}
      <Box
        style={{
          padding: "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "1%",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxWidth: "80%",
                  maxHeight: "70%",
                  marginLeft: "10%",
                }}
              >
                <img
                  src={warehouse}
                  className="d-block w-100"
                  style={{ borderRadius: "2%", maxHeight: "70%" }}
                  alt="..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxHeight: "100%",
                  maxWidth: "80%",
                  borderRadius: "2%",
                  marginLeft: "10%",
                  marginTop: "1%",
                }}
              >
                <Typography
                  variant="p"
                  style={{ fontSize: 20, fontFamily: "Segoe UI Italic" }}
                >
                  We offer complete <strong>warehouse services</strong> to take
                  care of all your needs. We receive, store, pack, track and
                  ship your products. Our warehouse management system provides
                  full control of the movement and storage of materials in the
                  warehouse and in your supply chain. <br />
                  Professionally managed and 100% secure, our warehouses
                  services are specially designed to cater to different
                  industrial standards and commercial needs. Whether you are
                  creating your own building, renting, or anything, it’s
                  necessary to buy top-quality warehousing services.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* End warehouse freight */}
      {/* Start Custom freight */}
      <Box
        style={{
          padding: "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "2%",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxHeight: "100%",
                  maxWidth: "80%",
                  borderRadius: "2%",
                  marginLeft: "10%",
                  marginTop: "2%",
                }}
              >
                <Typography
                  variant="p"
                  style={{ fontSize: 20, fontFamily: "Segoe UI Italic" }}
                >
                  Goods are exported and imported every day, but in order to
                  ensure they will reach their destination safely and timely, it
                  is necessary to hire a logistics company, as{" "}
                  <strong>New Era Logistics</strong>, that understands the legal{" "}
                  <strong>Custom Requirements</strong> of each country, in order
                  to make this transit process smooth.
                  <br /> Although the procedures and conditions may vary
                  according to the country’s customs, this process can be
                  complex regardless of where you are sending your shipment. If
                  you don’t know how to handle the customs clearance of your
                  shipment the appropriate way, it can cause a significant
                  impact on your business practices and operating costs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  maxWidth: "80%",
                  marginLeft: "10%",
                }}
              >
                <img
                  src={custom}
                  className="d-block w-100"
                  style={{ borderRadius: "2%" }}
                  alt="..."
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* End custom freight */}
    </>
  );
};

export default Services;
