import React from "react";
import { MailOutlineOutlined, PhoneAndroidOutlined } from "@mui/icons-material";
import "./footersection.css";
const FooterSection = () => {
  return (
    <div className="footer">
      <p>
        {" "}
        <PhoneAndroidOutlined /> +92 308 4557448
      </p>
      <p>
        {" "}
        <MailOutlineOutlined /> info@neweralogistics.pk
      </p>
      <p>© ALL OF THE RIGHTS RESERVED</p>
    </div>
  );
};

export default FooterSection;
