import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import img from "../../assets/logo/logo.jpg"
import { Link } from "react-router-dom";

const pages = ["home", "about", "services", "contact"];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            style={{
              color: "#00eeff65",
              textDecoration: "none",
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Segoe UI Italic",
              fontWeight: 700,
              letterSpacing: ".3rem",
            }}
            to="/"
          >
            <img style={{ width: "150px" }} src={img} alt="" />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link
                  to={page}
                  key={page}
                  className="nav-links-dropdown"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      style={{ fontWeight: 700, fontFamily: "Segoe UI Italic" }}
                      textAlign="center"
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            style={{ marginRight: "10%", justifyContent: "end" }}
          >
            {pages.map((page) => (
              <Link
                to={page}
                key={page}
                className="nav-links"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    cursor: "pointer",
                    fontWeight: 700,
                    fontFamily: "Segoe UI Italic",
                    color: "black",
                    zIndex: 1,
                  }}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 3.5, display: "block" }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
