import "./carousel.css";
import React from "react";
import airFreight from "../../assets/CarouselImages/AirFreight.jpg";
import seeFreight from "../../assets/CarouselImages/SeaFreight.jpg";
import container from "../../assets/CarouselImages/FCL&LCL.jpg";
import warehouse from "../../assets/CarouselImages/Warehouse.jpg";
import customeclearnce from "../../assets/CarouselImages/customeclearnce.jpg";

const Carousel = () => {
  return (
    <>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={airFreight}
              className="d-block w-100"
              alt="Flight Freight"
            />
          </div>
          <div className="carousel-item">
            <img
              src={seeFreight}
              className="d-block w-100"
              alt="Flight Freight"
            />
          </div>
          <div className="carousel-item">
            <img src={container} className="d-block w-100" alt="Warehouse" />
          </div>
          <div className="carousel-item">
            <img src={warehouse} className="d-block w-100" alt="Containers" />
          </div>
          <div className="carousel-item">
            <img
              src={customeclearnce}
              className="d-block w-100"
              alt="CustomClearnce"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <hr
        style={{
          marginBottom: "-1px",
          marginTop: "-1px",
          background: "#b28800",
          height: "7px",
          borderRadius: "4px",
          boxShadow: "1px 1px 2px #b28800, 0 0 25px #b28800, 0 0 5px #b28800",
        }}
      />
    </>
  );
};

export default Carousel;
