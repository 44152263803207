import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import transpotation from "../../assets/ServicesImages/transpotation.jpg";
import air from "../../assets/ServicesImages/airfreight.jpg";
import custom from "../../assets/ServicesImages/CustomsClearance.jpg";
import fcl from "../../assets/ServicesImages/FCL.png";
import lcl from "../../assets/ServicesImages/LCL.png";
import warehouse from "../../assets/ServicesImages/warehouse.jpg";

const ServicesSection = () => {
  return (
    <>
      <Box
        style={{
          padding: "2%",
          backgroundColor: "#fffbe9",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "2%",
            fontFamily: "Segoe UI Italic",
            fontWeight: 700,
          }}
          variant="h3"
        >
          Our Services
        </Typography>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            spacing={1}
            style={{
              marginTop: "2%",
              padding: "2%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={warehouse}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        WareHousing
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        We offer complete warehouse services to take care of all
                        your needs. We receive, store, pack, track and ship your
                        products.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={fcl}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Full Container Load
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        FCL or Full container load, which you purchase one or
                        more full containers to send on a ship.where your
                        products share a container
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={lcl}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Less Container Load
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        LCL or Less than container load, where your products
                        share a container as you may not have a full container’s
                        worth.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={custom}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Custom Clearance
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Customs clearance is a necessary procedure in permitting
                        goods that are transported to a country through an
                        authorized customs broker.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={transpotation}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Transportation
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Transportation in a supply chain refers to the movement
                        of products from one location to another, which begins
                        at the start of the supply as materials make their way
                        to delivered at the doorstep.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={"auto"} md={4} lg={3}>
              <Card sx={{ maxWidth: 340 }}>
                <Link
                  to="/services"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={air}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginTop: "5%", marginBottom: "5%" }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Air Freight
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontFamily: "Segoe UI Italic" }}
                      >
                        Air Freight is highly beneficial for import and export
                        business. It is advantageous if you send or receive
                        bulky items from one country to another with reasonable
                        rates.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <hr
        style={{
          marginBottom: "-1px",
          marginTop: "-1px",
          background: "#b28800",
          height: "7px",
          borderRadius: "4px",
          boxShadow: "1px 1px 2px #b28800, 0 0 25px #b28800, 0 0 5px #b28800",
        }}
      />
    </>
  );
};

export default ServicesSection;
