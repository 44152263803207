import * as React from "react";
import { Typography, Box, Grid } from "@mui/material";
import about from "../../assets/AboutusImages/LOG-process-0.jpg";

const AboutSection = () => {
  return (
    <>
      <Box
        style={{
          padding: "2%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Segoe UI Italic",
            fontWeight: 700,
          }}
          variant="h3"
        >
          About Us
        </Typography>
        <Box
          sx={{
            width: "100%",
            marginTop: "2%",
          }}
        >
          <Grid
            container
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={4}>
              <Box
                style={{
                  maxHeight: "50%",
                  maxWidth: "70%",
                  marginLeft: "15%",
                }}
              >
                <img
                  src={about}
                  className="d-block w-100"
                  style={{ borderRadius: "10%", maxHeight: "20%" }}
                  alt="..."
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                style={{
                  maxHeight: "100%",
                  maxWidth: "70%",
                  borderRadius: "5%",
                  marginLeft: "15%",
                }}
              >
                <Typography
                  variant="p"
                  //   fontSize="1.5vw;"
                  style={{
                    fontSize: 20,
                    lineHeight: 1.5,
                    fontFamily: "Segoe UI Italic",
                  }}
                >
                  Our mission is to deliver the best{" "}
                  <strong>logistics solutions</strong> for our customers to
                  fulfill their business commitments. We are dedicated to our
                  relationship with our customers and their success. We thrive
                  on challenges and consistently go above and beyond to provide
                  our clients with world-class quality logistics services which
                  are customized to meet their distinctive needs. <br />
                  We are keen to deliver the highest quality freight forwarding
                  solutions and service, with commitment, integrity and energy
                  at realistic costs all under one roof with our prime focus on
                  satisfied customers. <br />
                  Achieving our mission demands, a sense of trust, support, and
                  interdependence among these three vital elements of our
                  business:
                  <ul>
                    <li style={{ float: "left", margin: "0 10px" }}>Our Customers</li>
                    <li style={{ float: "left", margin: "0 20px" }}>Our Vendors</li>
                    <li style={{ float: "left", margin: "0 20px" }}>Our Team</li>
                  </ul>
                </Typography>
                <br />
                {/* <Box style={{ textAlign: "center", marginTop: "5%" }}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button variant="outlined" color="inherit">
                      About us
                    </Button>
                  </Link>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <hr
        style={{
          marginBottom: "-1px",
          marginTop: "-1px",
          background: "#b28800",
          height: "7px",
          borderRadius: "4px",
          boxShadow: "1px 1px 2px #b28800, 0 0 25px #b28800, 0 0 5px #b28800",
        }}
      />
    </>
  );
};

export default AboutSection;
